import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import $ from 'jquery';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import RouterHelper from '../core/RouterHelper';
import ShallowRenderedComponent from './base/ShallowRenderedComponent';
import UserManager from '../core/UserManager';
import withTrackChangedUser from '../core/withTrackChangedUser';
import WhiteLabelController from '../core/WhiteLabelController';
import OAuthSyncContainer from './markup/OAuthSyncContainer';
import GoogleAdsLogo from '../img/gads-justlogo.png';
import DV360Logo from '../img/dv360-justlogo.png';
import DefaultProfilePic from '../img/default_profile_pic.png';
import Button from 'components/markup/Button';

class MainMenu extends ShallowRenderedComponent {

	static propTypes = {
		title: PropTypes.string,
		globalOAuthData: PropTypes.object,
		tabOptions: PropTypes.array,
		tabOnSelect: PropTypes.func,
		extraWidth: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			parentShowId: null,
			showProfileDropdown: false
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	isImpersonating = () => {
		const originalAuthToken = localStorage.getItem('auth_token_original_user');
		const currentAuthToken = localStorage.getItem('auth_token');
		return !_.isEmpty(originalAuthToken) && !(originalAuthToken === currentAuthToken);
	}

	scrollToTop() {
		$('html, body').animate({ scrollTop: 0 }, 'fast');
		return null;
	}

	onParentMenuClick = () => {
		this.scrollToTop();
		if (window.location.pathname.includes('/exports_manager')) this.props.onFetchExportsStatus();
	}

	onChildMenuClick = path => {
		let element = document.getElementById(path);
		element.classList.remove('visible');
		this.scrollToTop();
	}

	renderNavItems() {
		const { canAccessAuditQueue, canAccessBlocklist, canAccessBuild, canAccessBSTE, canAccessDataTracker, canAccessInspect,
			canAccessDomainManager, canAccessPacingReport, canAccessReview, canAccessMyCustomData, canAccessUmbrellaTopics, canAccessChannelTagger,
			canAccessForecasting, canAccessResearch, canAccessTools, canAccessUserAnalytics, canAccessUserManager, canAccessAdmin,
			domainAccessToBuild, canAccessReporting, canAccessComscoreEditor, domainAccessToExportsManager, firstName, lastName} = UserManager.user;

		const renderChildItem = (path, title, isVisible = true) => {
			if (isVisible) {
				return <div key={title} className='child-item'>
					<Link to={path} reloadDocument>{title}</Link>
				</div>;
			}
		};
		const renderParentItem = (path, title, children, isVisible = true) => {
			const { parentShowId } = this.state;
			const selectedItem = window.location.pathname.includes(path);
			const menuOpened = parentShowId===title;
			return <div className={cx('has-child', {hidden: !isVisible, 'selected': selectedItem, 'opened': menuOpened})} onClick={() => this.setState({ parentShowId: title })}>
				<span className="parent-layer-menu">
					{title}
					<span className="material-symbols-outlined chevron">chevron_right</span>
				</span>
				<div id={path} className={cx('child-menu', { 'visible': menuOpened })} onClick={() => this.onChildMenuClick(path)}>{children}</div>
			</div>;
		};
		const renderSinglePathItem = (path, title, isVisible = true, extra=null) => {
			if (isVisible) {
				const selectedItem = window.location.pathname.includes(path);
				return <Link to={path} className={cx('single-item', {'selected':selectedItem})} onClick={this.onParentMenuClick} reloadDocument>
					{extra}
					{title}
				</Link>;
			}
			return null;
		};
		const renderExportStatus = () => {
			const { exportsStatus } = this.props;
			if (exportsStatus.Complete > 0) {
				return <div className='exports-status'>
					<div className='completed'>{exportsStatus.Complete > 99 ? 99 : exportsStatus.Complete}</div>
					{exportsStatus.Processing > 0 && <div className='processing completed'><i className='fa fa-refresh rotating-i'/></div>}
				</div>;
			} else if (!exportsStatus.Complete && exportsStatus.Processing > 0) {
				return <div className='exports-status'>
					<div className='processing'><i className='fa fa-refresh rotating-i'/></div>
				</div>;
			}
			return null;
		};
		const renderImpersonationBar = () => {
			return <div className='impersonation-bar'>
				<div className='bar-text'>You are logged in as<br />{firstName} {lastName}.</div>
				<div className='btn-block'>
					<Button className='btn return-btn' onClick={() => UserManager.loginAsOriginalUser()}>Back to my account</Button>
				</div>
			</div>;
		};
		return <div className="navitems-container">
			{renderSinglePathItem('/insights', 'Insights', canAccessResearch)}
			{renderSinglePathItem('/build', 'Build', canAccessBuild && domainAccessToBuild)}
			{renderSinglePathItem('/report_plus', 'Reporting+', canAccessReporting)}
			{renderSinglePathItem('/review', 'Review', canAccessReview)}
			{renderSinglePathItem('/inspect', 'Inspect', canAccessInspect)}
			{renderSinglePathItem('/forecasting', 'Forecasting', canAccessForecasting)}
			{renderParentItem('/tools', 'Tools', [
				renderChildItem('/tools/pacing_report', 'Pacing Report', canAccessPacingReport),
				renderChildItem('/tools/custom_data', 'My Custom Data', canAccessMyCustomData),
				renderChildItem('/tools/audit_queue', 'Audit Queue', canAccessAuditQueue),
				renderChildItem('/tools/custom_tagger', 'Channel Tagger', canAccessChannelTagger)
			], canAccessTools)}
			{renderParentItem('/admin', 'Admin', [
				renderChildItem('/admin/user_analytics', 'User Analytics', canAccessUserAnalytics),
				renderChildItem('/admin/users', 'User List', canAccessUserManager),
				renderChildItem('/admin/domain_manager', 'Domain Manager', canAccessDomainManager),
				renderChildItem('/admin/keywords_editor', 'Keywords Editor', canAccessBSTE),
				renderChildItem('/admin/blocklist_manager', 'Blocklist Manager', canAccessBlocklist),
				renderChildItem('/admin/data_tracker', 'Data Tracker', canAccessDataTracker),
				renderChildItem('/admin/comscore_editor', 'Comscore Editor', canAccessComscoreEditor),
				renderChildItem('/admin/umbrella_topics', 'Umbrella Topics', canAccessUmbrellaTopics)
			], canAccessAdmin)}
			{renderSinglePathItem('/exports_manager', 'Exports', domainAccessToExportsManager, renderExportStatus())}
			{this.isImpersonating() && renderImpersonationBar()}
		</div>;
	}

	onUserMenuSelect = active => {
		return active.action();
	};

	render() {
		const { showProfileDropdown } = this.state;
		const { children, globalOAuthData } = this.props;
		const { canAccessServiceDesk, profileImageUrl, fullName, email, logoUrl } = UserManager.user;
		const { googleAdsUserData, dv360UserData } = globalOAuthData;
		const siteLogoURL = window?.location?.host==='www.viewiq.com' || window?.location?.host==='rc.viewiq.com' ? logoUrl : WhiteLabelController.siteAssets.logo;
		const hasOAuth = !_.isEmpty(globalOAuthData);
		return <div>
			<div className="page-header" id='page-header' onMouseLeave={() => this.setState({ parentShowId: null, showProfileDropdown: false })}>
				{siteLogoURL && <div className="main-logo">
					<Link to={RouterHelper.takeHome()} reloadDocument>
						<img className="logo" src={siteLogoURL}/>
					</Link>
					{UserManager.user.isAdminUser && <span className='version'>{`${viewiq.BUILD_VERSION}`}</span>}
				</div>}
				{this.renderNavItems()}
				<div className='empty-area'/>
				<div className='user-items' onClick={() => this.setState({ showProfileDropdown: true })}>
					<div className={cx('dropdown-container', {'visible':showProfileDropdown})}>
						<div className='user-info'>
							<div className='full-name'>{fullName}</div>
							<div className='email'>{email}</div>
						</div>
						{hasOAuth && <OAuthSyncContainer globalOAuthData={globalOAuthData}/>}
						{canAccessServiceDesk && <div className='item service'>
							<div className='img-container'><span className='material-symbols-outlined'>lightbulb</span></div>
							<a className='email' href='https://channelfactory.atlassian.net/servicedesk/customer/portals' target='_blank'>Help Center</a>
						</div>}
						<div className='profile-options'>
							<div className='opt mb-10' onClick={() => RouterHelper.goTo('/my_profile')}><span className='material-symbols-outlined mr-10'>account_circle</span>My Profile</div>
							<div className='opt' onClick={() => UserManager.logout()}><span className='material-symbols-outlined mr-10'>logout</span>Log out</div>
						</div>
					</div>
					<div className='icons'>
						{canAccessServiceDesk && <div className='item service'><div className='img-container'><span className='material-symbols-outlined'>lightbulb</span></div></div>}
						{hasOAuth && <div className='item'>
							{_.isEmpty(googleAdsUserData) ? null :
								googleAdsUserData?.synced ? <div className='synced dot'><i className='fa fa-check'/></div> :
								<div className='syncing dot'><span className='material-icons'>autorenew</span></div>}
							<div className={cx('img-container google',{'not-active':_.isEmpty(googleAdsUserData),'syncing':!_.isEmpty(googleAdsUserData)&&!googleAdsUserData?.synced})}>
								<img className='asset' src={GoogleAdsLogo}/>
							</div>
						</div>}
						{hasOAuth && <div className='item'>
							{_.isEmpty(dv360UserData) ? null :
								dv360UserData?.synced ? <div className='synced dot'><i className='fa fa-check'/></div> :
								<div className='syncing dot'><span className='material-icons'>autorenew</span></div>}
							<div className={cx('img-container dv360',{'not-active':_.isEmpty(dv360UserData),'syncing':!_.isEmpty(dv360UserData)&&!dv360UserData?.synced})}>
								<img className='asset' src={DV360Logo}/>
							</div>
						</div>}
						<div className='item profile'>
							<div className='img-container profile'><img className='asset' src={profileImageUrl || DefaultProfilePic}/></div>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-60" style={{minWidth:'1350px'}}>
				{children}
			</div>
		</div>;
	}
}


export default withTrackChangedUser(MainMenu);
