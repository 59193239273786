import _ from 'lodash';
import { Person } from './BaseModels';
import dateUtil from '../utils/dates';
import WhiteLabelController from '../core/WhiteLabelController';
import { capitalizeName } from '../components/filters/utils';

export class User extends Person {

	static AD_SPENDS = {
		LOW: '$0-$100K',
		MEDIUM: '$100K-$250K',
		HIGH: '$250k+'
	};

	static USER_TYPES = {
		BRAND: 'brand',
		AGENCY: 'agency'
	};

	static STATUSES = {
		PENDING: 'pending',
		REJECTED: 'rejected',
		ACTIVE: 'active'
	};

	static fromJSON(json) {
		const fullname = _.trimStart(`${json.first_name || ''} ${json.last_name || ''}`);
		return _.extend(super.fromJSON(json), {
			id: json.id,
			perms: json.perms,
			authToken: json.token,
			company: json.company,
			phoneNumber: json.phone_number,
			phoneNumberVerified: json.phone_number_verified,
			profileImageUrl: json.profile_image_url,
			logoUrl: json.logo_url,
			hasAcceptedGDPR: json.has_accepted_GDPR,
			registrationDate: dateUtil.dateToLocalZone(json.date_joined),
			lastLoginDate: dateUtil.dateToLocalZone(json.last_login),
			historicalAWAccountId: json.historical_aw_account,
			hasDisapprovedAd: json.has_disapproved_ad,
			annualAdSpend: json.annual_ad_spend,
			userType: json.user_type,
			isImpersonating: json.isImpersonating,
			// only for few users
			hideBrandName: json.hide_brand_name,
			hideChannelsAndVideosOnDashboard: json.hide_channels_and_videos_on_dashboard,
			status: json.status,
			domain: json.domain,
			region: json.region_id,
			country: json.country_id,
			domainId: json.domain_id || json.domain?.id,
			role: json.role,
			roleId: json.role_id,
			isActive: json.is_active,
			firstName: json.first_name,
			lastName: json.last_name,
			userFullName: !_.isEmpty(fullname) ? capitalizeName(fullname) : 'N/A',
			productTypeID: json.iq_product_selected,
			productTypeName: json.iq_product_name,
			contractualWarning: User.checkContractualWarning(json),
			userSiteLogo: json.logo_url
		});
	}

	static toJSON(data) {
		return {
			first_name: data.firstName,
			last_name: data.lastName,
			company: data.company,
			phone_number: data.phoneNumber,
			email: data.email,
			has_accepted_GDPR: data.has_accepted_GDPR,
			verify_password: data.verifyPassword,
			password: data.password,
			annual_ad_spend: data.annualAdSpend,
			user_type: data.userType,
			region_id: data.region,
			country_id: data.country
		};
	}

	static checkContractualWarning(data) {
		if (!data.iq_product_selected) return false // no selection means do not check
		if (!data.iq_product) return false // no permissions to evaluate mismatch
		if (data.iq_product_selected == 2) return false // 2 is iq series which is all inclusive
		return data.iq_product_selected !== data.iq_product
	}

	get isAdminUser() {
		return this.checkAccess('admin');
	}

	get canAccessComscoreEditor() {
		return this.checkAccess('admin');
	}

	get canAccessUmbrellaTopics() {
		return this.checkAccess('nlp_umbrella') || this.checkAccess('admin');
	}

	get canAccessAdmin() {
		return this.canAccessUserManager || this.canAccessUserAnalytics || this.canAccessDomainManager || this.canAccessBSTE ||
			this.canAccessBlocklist || this.canAccessDataTracker || this.canAccessComscoreEditor || this.canAccessUmbrellaTopics;
	}

	get canAccessAdsAnalyzerRecipients() {
		return this.checkAccess('ads_analyzer.recipients') || this.isAdminUser;
	}

	get canAccessAuditQueue() {
		return (this.checkAccess('audit_queue') && this.domainAccessToAuditQueue) || this.isAdminUser;
	}

	get canAccessAuditQueueCreate() {
		return this.checkAccess('audit_queue.create') || this.isAdminUser;
	}

	get canAccessAuditQueuePriority() {
		return this.checkAccess('audit_queue.set_priority') || this.isAdminUser;
	}

	get canAccessAuditQueueImages() {
		return this.checkAccess('audit_queue.images') || this.isAdminUser;
	}

	get canAccessChannelTagger() {
		return this.checkAccess('channel_tagger') || this.isAdminUser;
	}

	get canAccessBlocklist() {
		return this.checkAccess('blocklist_manager') || this.isAdminUser;
	}

	get canAccessBlocklistCreateChannel() {
		return this.checkAccess('blocklist_manager.create_channel') || this.isAdminUser;
	}

	get canAccessBlocklistDeleteChannel() {
		return this.checkAccess('blocklist_manager.delete_channel') || this.isAdminUser;
	}

	get canAccessBlocklistExportChannel() {
		return this.checkAccess('blocklist_manager.export_channel') || this.isAdminUser;
	}

	get canAccessBlocklistCreateVideo() {
		return this.checkAccess('blocklist_manager.create_video') || this.isAdminUser;
	}

	get canAccessBlocklistDeleteVideo() {
		return this.checkAccess('blocklist_manager.delete_video') || this.isAdminUser;
	}

	get canAccessBlocklistExportVideo() {
		return this.checkAccess('blocklist_manager.export_video') || this.isAdminUser;
	}

	get canAccessBSTE() {
		return this.checkAccess('bste') || this.isAdminUser;
	}

	get canAccessBSTECreate() {
		return this.checkAccess('bste.create') || this.isAdminUser;
	}

	get canAccessBSTEDelete() {
		return this.checkAccess('bste.delete') || this.isAdminUser;
	}

	get canAccessBSTEExport() {
		return this.checkAccess('bste.export') || this.isAdminUser;
	}

	get canAccessBuild() {
		return this.checkAccess('build') || this.isAdminUser;
	}

	get canAccessBuildSeeCardFilters() {
		return this.checkAccess('build.ctl_show_used_filters') || this.isAdminUser;
	}

	get canAccessBuildDV360Extension() {
		return this.checkAccess('build.dv360_extension') || this.isAdminUser;
	}

	get canAccessBuildDomainsShare() {
		return this.checkAccess('build.ctl_share_with_domains') || this.isAdminUser;
	}

    get canAccessBuildDashboard() {
        return this.checkAccess('build.ctl_inventory') || this.isAdminUser;
    }

	get canAccessBuildRolesShare() {
		return this.checkAccess('build.ctl_share_with_roles') || this.isAdminUser;
	}

	get canAccessBuildUsersShare() {
		return this.checkAccess('build.ctl_share_with_users') || this.isAdminUser;
	}

	get canAccessBuildPushGAds() {
		return this.checkAccess('build.ctl_push_to_gads') || this.isAdminUser;
	}

	get canAccessCTLCreate() {
		return this.checkAccess('build.ctl_create') || this.isAdminUser;
	}

	get canAccessBuildOracle() {
		return this.checkAccess('build.ctl_oracle') || this.isAdminUser;
	}

	get canAccessCTLDelete() {
		return this.checkAccess('build.ctl_delete') || this.isAdminUser;
	}

	get canAccessBuildEditAll() {
		return this.checkAccess('build.ctl_edit_all') || this.isAdminUser;
	}

	get canAccessCTLExport() {
		return this.checkAccess('build.ctl_export_basic') || this.isAdminUser;
	}

	get canAccessCTLExportAdmin() {
		return this.checkAccess('build.ctl_export_admin') || this.isAdminUser;
	}

	get canAccessCTLConscious() {
		return this.checkAccess('build.ctl_pillar_associations') || this.isAdminUser;
	}

	get canAccessCTLFeature() {
		return this.checkAccess('build.ctl_feature_list') || this.isAdminUser;
	}

	get canAccessCTLSeeAll() {
		return this.checkAccess('build.ctl_see_all') || this.isAdminUser;
	}

	get canAccessCTLVetEnable() {
		return this.checkAccess('build.ctl_vet_enable') || this.isAdminUser;
	}

	get canAccessCTLVet() {
		return this.checkAccess('build.ctl_vet') || this.isAdminUser;
	}

	get canAccessCTLVetBasic() {
		return this.checkAccess('build.ctl_vet_basic') || this.isAdminUser;
	}

	get canAccessCTLCELists() {
		return this.checkAccess('build.ctl_assign_standard_chrome_extension_lists') || this.isAdminUser;
	}

	get canAccessCustomVettingData() {
		return this.checkAccess('build.ctl_custom_vetting_data') || this.isAdminUser;
	}

	get canAccessComscore() {
		return this.checkAccess('build.ctl_comscore_segments') || this.isAdminUser;
	}

    get canExportAllListsComscore() {
        return this.checkAccess('build.ctl_export_all_lists_comscore') || this.isAdminUser;
    }

	get canAccessReportVettingIssue() {
		return this.checkAccess('build.ctl_report_vetting_issue') || this.isAdminUser;
	}

	get canAccessCTLCSVUpload() {
		return this.checkAccess('build.ctl_from_custom_list') || this.isAdminUser;
	}

	get canAccessCTLVetExport() {
		return this.checkAccess('build.ctl_vet_export') || this.isAdminUser;
	}

	get canAccessCTLVideoEx() {
		return this.checkAccess('build.ctl_video_exclusion') || this.isAdminUser;
	}

	get canAccessCTLSentiment() {
		return this.checkAccess('build.ctl_sentiment') || this.isAdminUser;
	}

	get canAccessTemplates() {
		return this.checkAccess('build.ctl_params_template') || this.isAdminUser;
	}

	get canAccessPrimaryCategories() {
		return this.checkAccess('build.ctl_relevant_primary_categories') || this.isAdminUser;
	}

	get canAccessIABThreeCategories() {
		return this.checkAccess('build.ctl_iab_categories_3') || this.isAdminUser;
	}

	get canAccessGarmSuitability() {
		return this.checkAccess('build.ctl_advanced_suitability') || this.isAdminUser;
	}

	get canAccessNLPTopics() {
		return this.checkAccess('build.ctl_nlp_topics') || this.isAdminUser;
	}

	get canAccessNLPCuratedUmbrellas() {
		return this.checkAccess('build.nlp_umbrellas') || this.isAdminUser;
	}

	get canAccessProgrammaticAge() {
		return this.checkAccess('build.ctl_programmatic_age') || this.isAdminUser;
	}

	get canAccessProgrammaticGender() {
		return this.checkAccess('build.ctl_programmatic_gender') || this.isAdminUser;
	}

	get canAccessExportAll() {
		return this.checkAccess('export_admin') || this.isAdminUser;
	}

	get canAccessDomainManager() {
		return this.checkAccess('domain_manager') || this.isAdminUser;
	}

	get canDeleteDomains() {
		return this.checkAccess('domain_manager.delete') || this.isAdminUser;
	}

	get canAccessDomainManagerCreate() {
		return this.checkAccess('domain_manager.create') || this.isAdminUser;
	}

	get canAccessDomainManagerDelete() {
		return this.checkAccess('domain_manager.delete') || this.isAdminUser;
	}

	get canAccessManagedServiceExport() {
		return this.checkAccess('managed_service.export') || this.isAdminUser;
	}

	get canAccessManagedServicePerformanceGraph() {
		return this.checkAccess('managed_service.performance_graph') || this.isAdminUser;
	}

	get canAccessManagedServiceDelivery() {
		return this.checkAccess('managed_service.delivery') || this.isAdminUser;
	}

	get canAccessManagedServiceCampaignsSegmented() {
		return this.checkAccess('managed_service.campaigns_segmented') || this.isAdminUser;
	}

	get canAccessManagedServiceConversions() {
		return this.checkAccess('managed_service.conversions') || this.isAdminUser;
	}

	get canAccessManagedServiceAllAccounts() {
		return this.checkAccess('managed_service.visible_all_accounts') || this.isAdminUser;
	}

	get canAccessManagedServiceRealGAdsCost() {
		return this.checkAccess('managed_service.real_gads_cost') || this.isAdminUser;
	}

	get canAccessManagedServiceGlobalAccount() {
		return this.checkAccess('managed_service.global_account_visibility') || this.isAdminUser;
	}

	get canAccessManagedServiceAudiences() {
		return this.checkAccess('managed_service.audiences') || this.isAdminUser;
	}

	get canAccessManagedServiceCost() {
		return this.checkAccess('managed_service.service_costs') || this.isAdminUser;
	}

	get canAccessManagedServiceChannelVideoTabs() {
		return this.checkAccess('managed_service.channel_video_tabs') || this.isAdminUser;
	}

	get canAccessMyCustomData() {
		return this.checkAccess('client_custom_data') || this.isAdminUser;
	}

	get canAccessMyCustomDataAll() {
		return this.checkAccess('client_custom_data.see_all') || this.isAdminUser;
	}

	get canAccessMyCustomDataDomainsShare() {
		return this.checkAccess('client_custom_data.share_with_domains') || this.isAdminUser;
	}

	get canAccessMyCustomDataRolesShare() {
		return this.checkAccess('client_custom_data.share_with_roles') || this.isAdminUser;
	}

	get canAccessMyCustomDataUsersShare() {
		return this.checkAccess('client_custom_data.share_with_users') || this.isAdminUser;
	}

	get canAccessPacingReport() {
		return (this.checkAccess('pacing_report') && this.domainAccessToPacingReport) || this.isAdminUser;
	}

	get canAccessReview() {
		return (this.checkAccess('performiq') && this.domainAccessToReview) || this.isAdminUser;
	}

	get canAccessReviewExport() {
		return this.checkAccess('performiq.export') || this.isAdminUser;
	}

	get canAccessReviewSuitability() {
		return this.checkAccess('performiq.suitability') || this.isAdminUser;
	}

	get canAccessReviewCSVUpload() {
		return this.checkAccess('performiq.csv_upload') || this.isAdminUser;
	}

	get canAccessInspect() {
		return this.checkAccess('inspect') || this.isAdminUser;
	}

	get canAccessForecasting() {
		return (this.checkAccess('pricing_tool') && this.domainAccessToForecasting) || this.isAdminUser;
	}

	get canAccessResearch() {
		return (this.checkAccess('research') && this.domainAccessToResearch) || this.isAdminUser;
	}

	get canAccessResearchAuth() {
		return this.checkAccess('research.auth') || this.isAdminUser;
	}

	get canAccessResearchAgeGender() {
		return this.checkAccess('research.age_gender') || this.isAdminUser;
	}

	get canAccessResearchBrandSuitability() {
		return this.checkAccess('research.brand_suitability') || this.isAdminUser;
	}

	get canAccessResearchBrandSuitabilityHighRisk() {
		return this.checkAccess('research.brand_suitability_high_risk') || this.isAdminUser;
	}

	get canAccessResearchDetail() {
		return (this.checkAccess('research.channel_video_view') && this.domainAccessToResearch) || this.isAdminUser;
	}

	get canAccessResearchLanguageData() {
		return this.checkAccess('research.language_data') || this.isAdminUser;
	}

	get canAccessResearchLanguageFlagging() {
		return this.checkAccess('research.language_flagging') || this.isAdminUser;
	}

	get canAccessResearchMonetization() {
		return this.checkAccess('research.monetization') || this.isAdminUser;
	}

	get canAccessResearchExport() {
		return this.checkAccess('research.export') || this.isAdminUser;
	}

	get canAccessResearchTranscripts() {
		return this.checkAccess('research.transcripts') || this.isAdminUser;
	}

	get canAccessResearchVetting() {
		return this.checkAccess('research.vetting') || this.isAdminUser;
	}

	get canAccessResearchVettingBasic() {
		return this.checkAccess('research.vetting_basic') || this.isAdminUser;
	}

	get canAccessResearchVettingData() {
		return this.checkAccess('research.vetting_data') || this.isAdminUser;
	}

	get canAccessResearchDislikes() {
		return this.checkAccess('research.sentiment') || this.isAdminUser;
	}

	get canAccessUserAnalytics() {
		return this.checkAccess('user_analytics') || this.isAdminUser;
	}

	get canAccessUserManager() {
		if (this.isImpersonating) return false;
		return this.checkAccess('user_management') || this.isAdminUser;
	}

	get canAccessUserManagerRoles() {
		return this.checkAccess('user_management.create_user_roles') || this.isAdminUser;
	}

	get canAccessTools() {
		return this.canAccessPacingReport || this.canAccessAuditQueue || this.canAccessMyCustomData;
	}

	get canAccessResearchIASOnly() {
		return this.checkAccess('research.ias_only') && !this.isAdminUser;
	}

	get canAccessBuildChatbot() {
		return this.checkAccess('build.chatbot') || this.isAdminUser;
	}

	get canAccessBuildIASOnly() {
		return this.checkAccess('build.ctl_ias_only') && !this.isAdminUser;
	}

	get canAccessDataTracker() {
		return this.checkAccess('data_tracker') || this.isAdminUser;
	}

    get canAccessReporting() {
		return (this.checkAccess('reporting') && this.domainAccessToReporting) || this.isAdminUser;
	}

	get canAccessReportingSyncWithSalesforce() {
		return this.checkAccess('reporting.salesforce_sync') || this.isAdminUser;
	}

	get canAccessReportingBulkExclusions() {
		return this.checkAccess('reporting.clear_cid_exclusions') || this.isAdminUser;
	}

	get canAccessReportingOpportunities() {
		return this.checkAccess('reporting.opportunities') || this.isAdminUser;
	}

	get canAccessReportingExports() {
		return this.checkAccess('reporting.schedule_exports') || this.isAdminUser;
	}

	get canAccessReportingOpportunityExports() {
		return this.checkAccess('reporting.schedule_exports_opportunities') || this.isAdminUser;
	}

	get canAccessReportingExportsOnBehalf() {
		return this.checkAccess('reporting.schedule_exports_on_behalf') || this.isAdminUser;
	}

	get canAccessReportingCost() {
		return this.checkAccess('reporting.actual_cost') || this.isAdminUser;
	}

	get canAccessReportingGAds() {
		return this.checkAccess('reporting.google_ads_data_access') || this.isAdminUser;
	}

	get canAccessReportingChannelVideo() {
		return this.checkAccess('reporting.channels_videos') || this.isAdminUser;
	}

	get canAccessReportingAdGroups() {
		return this.checkAccess('reporting.adgroups') || this.isAdminUser;
	}

	get canAccessReportingCampaignBreakout() {
		return this.checkAccess('reporting.campaign_breakout') || this.isAdminUser;
	}

	get canAccessReportingAccountManagers() {
		return this.checkAccess('reporting.show_account_manager') || this.isAdminUser;
	}

	get canAccessReportingRegionTerritory() {
		return this.checkAccess('reporting.region_and_territory') || this.isAdminUser;
	}

	get canAccessServiceDesk() {
		return this.checkAccess('service_desk') || this.isAdminUser;
	}

	get domainAccessToGoogleOAuth() {
		return this.checkDomainAccess('Google OAuth');
	}

	get domainAccessToResearch() {
		return this.checkDomainAccess('Insights') || this.isAdminUser;
	}

	get domainAccessToBuild() {
		return this.checkDomainAccess('Build') || this.isAdminUser;
	}

    get domainAccessToExportsManager() {
        return this.checkDomainAccess('Exports') || this.isAdminUser;
    }

	get domainAccessToReporting() {
		return this.checkDomainAccess('Reporting+') || this.isAdminUser;
	}

	get domainAccessToSelfService() {
		return this.checkDomainAccess('Analytics > Self Service') || this.isAdminUser;
	}

	get domainAccessToSelfServiceTrends() {
		return this.checkDomainAccess('Analytics > Self Service Trends') || this.isAdminUser;
	}

	get domainAccessToPacingReport() {
		return this.checkDomainAccess('Tools > Pacing Report') || this.isAdminUser;
	}

	get domainAccessToForecasting() {
		return this.checkDomainAccess('Forecasting') || this.isAdminUser;
	}

	get domainAccessToAuditQueue() {
		return this.checkDomainAccess('Tools > Audit Queue') || this.isAdminUser;
	}

	get domainAccessToReview() {
		return this.checkDomainAccess('Review') || this.isAdminUser;
	}

	checkDomainAccess(accessDisabled) {
		const hasDomainAccess = !WhiteLabelController.siteAssets.restricted.includes(accessDisabled);
		return hasDomainAccess;
	}

	checkAccess(accessName) {
		if (!_.isEmpty(this?.perms)) {
			return this.perms[accessName];
		}
		return false;
	}

	get isYoutubeDemoUser() {
		return this.email === 'ytmpdemo@gmail.com';
	}
}

export class UserAction {
	static fromJSON(json) {
		return _.extend(new this(), {
			id: json.id,
			slug: json.slug,
			firstName: json.first_name,
			lastName: json.last_name,
			email: json.email,
			url: json.url,
			createdAt: dateUtil.dateToLocalZone(json.created_at)
		});
	}
}

export class LocationPolygon {
	static isPointInPolygon(polygon, lat, lng) {
		let i,
			j,
			c = 0;
		for (i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
			if (polygon[i][0] > lng != polygon[j][0] > lng && lat < ((polygon[j][1] - polygon[i][1]) * (lng - polygon[i][0])) / (polygon[j][0] - polygon[i][0]) + polygon[i][1]) c = !c;
		}
		return c;
	}

	static fromJSON(lat, lng, locations) {
		let potentialCorrectLocations = _.filter(locations, location => _.get(location, 'geojson.type') === 'Polygon' || _.get(location, 'geojson.type') === 'MultiPolygon');

		let correctLocation = _.find(potentialCorrectLocations, location => {
			const sourceCoordinates = _.get(location, 'geojson.coordinates');
			if (_.get(location, 'geojson.type') === 'Polygon') {
				return LocationPolygon.isPointInPolygon(sourceCoordinates[0], lat, lng);
			}
			const coordinates = _.maxBy(sourceCoordinates, o => o[0].length)[0];
			return LocationPolygon.isPointInPolygon(coordinates, lat, lng);
		});

		let polygon = _.get(correctLocation, 'geojson.coordinates');
		if (_.get(correctLocation, 'geojson.type') === 'MultiPolygon') {
			const coordinates = _.get(correctLocation, 'geojson.coordinates');
			polygon = _.maxBy(coordinates, o => o[0].length);
		}
		polygon = _.map(polygon, p =>
			_.map(p, coords => {
				return { lng: coords[0], lat: coords[1] };
			})
		)[0];

		return _.extend(new this(), { polygon: _.size(polygon) > 100 ? polygon : null });
	}
}

export class WikipediaArticleModel {
	static fromJSON(data) {
		const pageId = _.keys(data.query.pages)[0];
		return _.extend(new this(), {
			url: _.get(data.query.pages[pageId], 'canonicalurl'),
			title: _.get(data.query.pages[pageId], 'displaytitle'),
			extract: _.get(data.query.pages[pageId], 'extract'),
			thumbnail: _.get(data.query.pages[pageId], 'thumbnail'),
			originalImage: _.get(data.query.pages[pageId], 'original')
		});
	}
}

export class TagsEditorBadWordModel {
	static fromJSON(json) {
		return _.extend(new this(), {
			id: json.id,
			name: json.name,
			category: json.category,
			language: json.language,
			score: json.negative_score,
			metaScoring: json.meta_scoring,
			exclCategories: json.excl_content_categories,
			killWord: json.kill_word,
			substringMatch: json.substring_match
		});
	}
}

export class TagsEditorHistoryItem {
	static fromJSON(json) {
		return _.extend(new this(), {
			id: json.id,
			tagName: json.tag_name,
			action: json.action,
			createdAt: json.created_at ? new Date(json.created_at) : null
		});
	}
}

export class UmbrellaTopicModel {
	static fromJSON(json) {
		return _.extend(new this(), {
			id: json.id,
			name: json.name,
			categories: json.iab_categories,
			topics: json.topics,
			deleted: _.isString(json.deleted_at),
			published: json.publish_state==1
		})
	}

	static toJSON(data) {
		const body = {
			name: data.name,
			iab_categories: data.categories,
			topics: data.topics,
			publish_state: data.published
		};
		if (data.id) body.id = data.id;
		return body;
	}
}

export class PermissionsLog {
	static fromJSON(json) {
		return _.extend(new this(), {
			date: json.date,
			positiveAction: _.includes(json.action, 'added'),
			actionUser: json.acting_user,
			value: json.field_value || null,
			targetUser: json.target_user,
			description: json.summary,
			type: json.field_type
		})
	}
}

export class RoleLog {
	static fromJSON(json) {
		return _.extend(new this(), {
			date: dateUtil.formatToOnlyDateFormat(json.date),
			positiveAction: _.includes(json.action, 'added'),
			actionUser: json.acting_user,
			permission: json.permission || null,
			user: json.user || null,
			roleID: json.role_id,
			roleName: json.role_name,
			description: json.summary
		})
	}
}
